import * as SelectPrimitive from "@radix-ui/react-select";
import { ChevronDown, ChevronUp } from "lucide-react";
import * as React from "react";

import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
		className?: string;
		hasError?: boolean;
	}
>(({ className, children, hasError, ...props }, ref) => (
	<SelectPrimitive.Trigger
		ref={ref}
		className={cn(
			`flex h-14 w-full items-center justify-between rounded-md border border-solid border-neutral-200 bg-transparent px-4 py-1 font-inter !text-P5 font-normal leading-160 text-neutral-1100 placeholder:text-muted-foreground focus:border-primary-600 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 [&[data-placeholder]]:text-neutral-500 [&[data-state=close]>svg]:text-neutral-500 [&[data-state=open]>svg]:rotate-180 [&[data-state=open]>svg]:text-neutral-1100 [&[data-state=open]]:border-primary-600 ${
				hasError ? "border-red-600 focus:border-red-600" : ""
			}`,
			className,
		)}
		{...props}
	>
		{children}
		<SelectPrimitive.Icon asChild>
			<ChevronDown className="h-5 w-5" />
		</SelectPrimitive.Icon>
	</SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> & {
		className?: string;
	}
>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollUpButton
		ref={ref}
		className={cn(
			"flex cursor-default items-center justify-center py-1",
			className,
		)}
		{...props}
	>
		<ChevronUp className="h-4 w-4" />
	</SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> & {
		className?: string;
	}
>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollDownButton
		ref={ref}
		className={cn(
			"flex cursor-default items-center justify-center py-1",
			className,
		)}
		{...props}
	>
		<ChevronDown className="h-4 w-4" />
	</SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
	SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
		className?: string;
		position?: "popper" | "item-aligned" | undefined;
	}
>(({ className, children, position = "popper", ...props }, ref) => (
	<SelectPrimitive.Portal>
		<SelectPrimitive.Content
			ref={ref}
			className={cn(
				"data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md",
				position === "popper" &&
					"rounded-[8px] border-none bg-neutral-100 text-neutral-1100 data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
				className,
			)}
			position={position}
			{...props}
		>
			<SelectScrollUpButton />
			<SelectPrimitive.Viewport
				className={cn(
					"p-1 !text-neutral-1100",
					position === "popper" &&
						"h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
				)}
			>
				{children}
			</SelectPrimitive.Viewport>
			<SelectScrollDownButton />
		</SelectPrimitive.Content>
	</SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Label>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> & {
		className?: string;
	}
>(({ className, ...props }, ref) => (
	<SelectPrimitive.Label
		ref={ref}
		className={cn("py-1.5 pl-8 pr-2 text-P5 font-semibold", className)}
		{...props}
	/>
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
		className?: string;
	}
>(({ className, children, ...props }, ref) => (
	<SelectPrimitive.Item
		ref={ref}
		className={cn(
			"relative flex w-full cursor-default select-none items-center rounded-[8px] p-2 font-inter text-P5 font-normal leading-160 text-neutral-500 outline-none hover:bg-neutral-200 hover:!text-neutral-1100 focus:bg-neutral-200 focus:text-neutral-1100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
			className,
		)}
		{...props}
	>
		<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
	</SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
	React.ElementRef<typeof SelectPrimitive.Separator>,
	React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> & {
		className?: string;
	}
>(({ className, ...props }, ref) => (
	<SelectPrimitive.Separator
		ref={ref}
		className={cn("-mx-1 my-1 h-px bg-muted", className)}
		{...props}
	/>
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

interface FloatingLabelSelectProps extends React.ComponentProps<typeof Select> {
	label: string;
	hasError?: boolean;
	bgColor?: string;
	onValueChange?: (value: string) => void;
}

const FloatingLabelSelect = React.forwardRef<
	HTMLDivElement,
	FloatingLabelSelectProps
>(({ label, hasError, bgColor, children, onValueChange, ...props }, ref) => {
	const [hasValue, setHasValue] = React.useState(!!props.value);
	const [isFocused, setIsFocused] = React.useState(false);

	const handleValueChange = (value: string) => {
		setHasValue(!!value);
		if (onValueChange) {
			onValueChange(value);
		}
	};

	return (
		<div ref={ref} className="relative">
			<Select
				{...props}
				onValueChange={handleValueChange}
				onOpenChange={setIsFocused} // Atualiza o estado quando o Select abre/fecha
			>
				<SelectTrigger
					className={cn(
						"peer placeholder-transparent",
						hasError ? "border-red-600 focus:border-red-600" : "",
						bgColor,
					)}
				>
					<SelectValue placeholder=" " />
				</SelectTrigger>
				{children}
			</Select>

			<Label
				className={cn(
					`absolute start-2 z-[5] origin-[0] transform bg-neutral-100 px-2 !text-P5 font-normal text-neutral-600 transition-all duration-300`,
					hasValue && "top-2 -translate-y-4 ",
					isFocused && "font-semibold text-neutral-1100",
					!hasValue && !isFocused && "top-1/2 -translate-y-1/2 scale-100",
					hasError && "!text-red-600",
					bgColor,
				)}
			>
				{label}
			</Label>
		</div>
	);
});
FloatingLabelSelect.displayName = "FloatingLabelSelect";

export {
	Select,
	SelectGroup,
	SelectValue,
	SelectTrigger,
	SelectContent,
	SelectLabel,
	SelectItem,
	SelectSeparator,
	SelectScrollUpButton,
	SelectScrollDownButton,
	FloatingLabelSelect,
};
