import { deleteNotification } from "@/api/app/notifications/delete";
import { deleteAllNotification } from "@/api/app/notifications/delete-all";
import { readNotification } from "@/api/app/notifications/read";
import ChevronLeftIcon from "@/assets/chevron-left.svg?react";
import DropdownArrow from "@/assets/dropdown-arrow.svg?react";
import SearchIcon from "@/assets/search.svg?react";
import { useAuthStore } from "@/store/auth";

import { NotificationCard } from "./notification-card";

interface NotificationDropdownProps {
	closeDropdown: () => void;
}

export function NotificationDropdown({
	closeDropdown,
}: NotificationDropdownProps) {
	const { profile, setProfile } = useAuthStore();

	async function handleReadNotification(id: string) {
		const response = await readNotification(id);

		if (response.status === "success" && profile) {
			const notificationIndex = profile.notifications.data.findIndex(
				(notification) => notification.id === id,
			);

			if (notificationIndex !== -1) {
				const newProfile = { ...profile };
				newProfile.notifications.data[notificationIndex].read_at =
					new Date().toISOString();
				newProfile.notifications.meta.count_read += 1;
				setProfile(newProfile);
			}
		}
	}

	async function handleDeleteNotification(id: string) {
		const response = await deleteNotification(id);

		if (response.status === "success" && profile) {
			const newProfile = { ...profile };
			newProfile.notifications.data = newProfile.notifications.data.filter(
				(notification) => notification.id !== id,
			);
			setProfile(newProfile);
		}
	}

	async function handleDeleteAllNotifications() {
		const response = await deleteAllNotification();

		if (response.status === "success" && profile) {
			const newProfile = { ...profile };
			newProfile.notifications.data = [];
			setProfile(newProfile);
			closeDropdown();
		}
	}

	function getNotificationType(notification: string) {
		if (notification.includes("Novo produto adicionado")) {
			return "product";
		} else if (notification.includes("Novo treinamento adicionado")) {
			return "training";
		} else if (notification.includes("Novo comentário adicionado")) {
			return "comment";
		}
		return "unknown";
	}

	return (
		<div className="gap[-1px] flex w-full flex-col md:w-[400px]">
			<span className="hidden justify-end px-4 md:flex">
				<DropdownArrow />
			</span>

			<div className="flex h-screen flex-col items-center bg-neutral-100 px-4 md:h-auto md:rounded-xl">
				<div className="flex w-full items-center justify-between gap-4 rounded-xl bg-neutral-100 px-5 py-4 md:p-4">
					<button
						className="flex cursor-pointer items-center gap-3 md:cursor-text"
						onClick={closeDropdown}
					>
						<ChevronLeftIcon
							width={32}
							height={32}
							className="flex text-neutral-600 md:hidden"
						/>
						<p className="font-inter text-P2 font-medium leading-140 text-neutral-1100 md:text-P4 md:leading-160">
							Notificações
						</p>
					</button>

					{profile?.notifications.data.length ?? 0 > 0 ? (
						<button
							className="cursor-pointer font-inter text-P5 font-medium leading-160 text-neutral-400 transition-all duration-300 hover:text-neutral-1100 hover:underline hover:underline-offset-8"
							onClick={handleDeleteAllNotifications}
						>
							Limpar todas
						</button>
					) : null}
				</div>

				{profile?.notifications.data.length === 0 ? (
					<div className="flex flex-1 px-5 pb-8 md:flex-auto md:px-4 md:pb-4">
						<div className="flex flex-col items-center justify-center gap-6 px-11 py-[67px]">
							<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
								<SearchIcon />
							</span>

							<h3 className="text-center font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Você não possui nenhuma notificação no momento!
							</h3>
						</div>
					</div>
				) : (
					<div className="mb-4 flex max-h-[400px] min-h-[248px] w-full flex-1 flex-col gap-4 overflow-y-auto pr-2">
						{profile?.notifications.data.map((notification) => (
							<NotificationCard
								key={notification.id}
								createdAt={notification.created_at}
								title={notification.data.message}
								wasRead={notification.read_at}
								cleanNotification={() =>
									handleDeleteNotification(notification.id)
								}
								readNotification={() => {
									handleReadNotification(notification.id);
								}}
								// @ts-ignore
								lessonId={notification.data.data.lessonId || ""}
								trainingId={
									// @ts-ignore
									notification.data.data.trainingId ||
									notification.data.data.id ||
									""
								}
								// @ts-ignore
								moduleId={notification.data.data.moduleId || ""}
								productId={notification.data.data.id || ""}
								commentId={notification.data.data.id || ""}
								type={
									getNotificationType(notification.data.message) as
										| "product"
										| "training"
										| "comment"
								}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
}
