"use client";

import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { showComments } from "@/api/app/academy/trainings/comments";
import { deleteComment } from "@/api/app/academy/trainings/delete-comment";
import { likeComment } from "@/api/app/academy/trainings/like-comment";
import { saveAnswer } from "@/api/app/academy/trainings/save-answer";
import { saveComment } from "@/api/app/academy/trainings/save-comment";
import Check2Icon from "@/assets/check-2.svg?react";
import ChevronDownIcon from "@/assets/chevron-down.svg?react";
import { Comment } from "@/components/comment";
import { DeleteModal } from "@/components/delete-modal";
import { InputComment } from "@/components/input-comment";
import { useAuthStore } from "@/store/auth";
import { useCommentStore } from "@/store/comment";

export function AcademyTrainingComments() {
	const [showOrderComments, setShowOrderComments] = useState(false);
	const [currentOrderBy, setOrderBy] = useState("Mais curtidas");
	const [isLoading, setIsLoading] = useState(false);
	const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [selectedComment, setSelectedComment] = useState<string | null>(null);
	const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);

	const commentRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

	const orderBy = [
		{ id: 1, name: "Mais curtidas" },
		{ id: 2, name: "Mais recentes" },
		{ id: 3, name: "Menos recentes" },
	];

	const { lessonId, commentId } = useParams();

	const { comment, setComment } = useCommentStore();
	const { profile } = useAuthStore();

	useEffect(() => {
		if (commentId && comment && comment.length > 0) {
			setTimeout(() => {
				const targetComment = comment.find((c) => c.id === commentId);
				if (targetComment && commentRefs.current[commentId]) {
					commentRefs.current[commentId]?.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
				} else {
					alert("Comentário não encontrado");
				}
			}, 1000);
		}
	}, [commentId, comment]);

	async function handleOrderBy(order: string) {
		setOrderBy(order);
		setShowOrderComments(false);

		const response = await showComments({
			lessonId: lessonId,
			perPage: 200,
			sortBy: `${
				order === "Mais curtidas"
					? "likes-DESC"
					: order === "Mais recentes"
						? "created_at-DESC"
						: "created_at-ASC"
			}`,
		});

		if (response.status === "success") {
			setComment(response.data);
		}
	}

	async function addNewComment(newComment: string) {
		setIsLoading(true);

		if (!lessonId) return;
		if (!comment) return;

		const response = await saveComment(newComment, lessonId);

		if (response.status === "success") {
			await handleOrderBy("Mais recentes");
		}

		setIsLoading(false);
	}

	async function addNewAnswer(newAnswer: string, commentId: string) {
		setIsLoading(true);

		if (!lessonId) return;
		if (!comment) return;

		const response = await saveAnswer(newAnswer, lessonId, commentId);

		if (response.status === "success") {
			const data = {
				id: Math.random().toString(),
				content: newAnswer,
				likes: 0,
				user_name: profile?.name || "",
				user_id: profile?.id || "",
				user_image: profile?.image || "",
				created_at: new Date().toDateString(),
				logged_user_liked: false,
			};

			const newComments = comment.map((comment) => {
				if (comment.id === commentId) {
					return {
						...comment,
						answers: [data, ...comment.answers],
					};
				}

				return comment;
			});

			setComment(newComments);
		}

		setIsLoading(false);
		setActiveCommentId(null);
	}

	async function handleDeleteComment() {
		setIsLoading(true);

		if (!selectedComment && !selectedAnswer) {
			setIsLoading(false);
			return;
		}

		if (!comment) {
			setIsLoading(false);
			return;
		}

		const commentIdToDelete = selectedComment || selectedAnswer;
		if (!commentIdToDelete) {
			setIsLoading(false);
			return;
		}

		const response = await deleteComment(commentIdToDelete);

		if (response.status === "success") {
			if (selectedComment) {
				const newComments = comment.filter(
					(comment) => comment.id !== selectedComment,
				);
				setComment(newComments);
			} else if (selectedAnswer) {
				const newComments = comment.map((comment) => {
					const newAnswers = comment.answers.filter(
						(answer) => answer.id !== selectedAnswer,
					);
					return {
						...comment,
						answers: newAnswers,
					};
				});
				setComment(newComments);
			}
		}

		setSelectedComment(null);
		setSelectedAnswer(null);
		setDeleteModal(false);
		setIsLoading(false);
	}

	async function handleLikeComment(commentId: string, isAnswer = false) {
		setIsLoading(true);

		if (!comment) return;

		const response = await likeComment(commentId);

		if (response.status === "success") {
			const newComments = comment?.map((comment) => {
				if (comment.id === commentId) {
					return {
						...comment,
						likes: comment.logged_user_liked
							? comment.likes - 1
							: comment.likes + 1,
						logged_user_liked: !comment.logged_user_liked,
					};
				}

				if (isAnswer) {
					const newAnswers = comment.answers.map((answer) => {
						if (answer.id === commentId) {
							return {
								...answer,
								likes: answer.logged_user_liked
									? answer.likes - 1
									: answer.likes + 1,
								logged_user_liked: !answer.logged_user_liked,
							};
						}

						return answer;
					});

					return {
						...comment,
						answers: newAnswers,
					};
				}

				return comment;
			});

			setComment(newComments);
		}
		setIsLoading(false);
	}

	return (
		<div className="flex w-full flex-col gap-10 pb-20">
			<div className="flex items-center justify-between">
				<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					{comment?.length || 0} comentários
				</h3>

				<div className="relative">
					<button
						className="flex items-center gap-1 text-neutral-600 transition-all duration-300 hover:text-neutral-1100"
						onClick={() => setShowOrderComments(!showOrderComments)}
					>
						<p className="font-inter text-P5 font-medium leading-160">
							Ordenar por
						</p>
						<ChevronDownIcon width={20} height={20} />
					</button>

					{showOrderComments && (
						<ul
							className="absolute right-0 top-8 z-[5] flex w-[240px] flex-col gap-2 rounded-[8px] bg-neutral-100 p-2"
							style={{
								boxShadow: "0px 10px 40px -10px rgba(18, 23, 23, 0.10)",
							}}
						>
							{orderBy.map((order) => (
								<button
									key={order.id}
									className={`flex justify-between gap-4 rounded-[8px] p-2 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100 ${
										currentOrderBy === order.name ? "bg-neutral-200" : ""
									}`}
									onClick={() => {
										handleOrderBy(order.name);
									}}
								>
									<p className="font-inter text-P5 font-normal leading-160">
										{order.name}
									</p>
									{currentOrderBy === order.name && <Check2Icon />}
								</button>
							))}
						</ul>
					)}
				</div>
			</div>

			<InputComment
				onSubmit={(value) => {
					addNewComment(value);
				}}
				isLoading={isLoading}
			/>

			{comment?.map((comment) => (
				<div
					key={comment.id}
					ref={(el) => {
						commentRefs.current[comment.id] = el;
					}}
				>
					<Comment
						key={comment.id}
						id={comment.id}
						content={comment.content}
						likes={comment.likes}
						user_name={comment.user_name}
						user_image={comment.user_image || ""}
						created_at={comment.created_at}
						logged_user_liked={comment.logged_user_liked}
						handleAnswer={() =>
							setActiveCommentId(
								activeCommentId === comment.id ? null : comment.id,
							)
						}
						handleDelete={() => {
							setDeleteModal(true);
							setSelectedComment(comment.id);
						}}
						handleLike={() => handleLikeComment(comment.id)}
						isOwnerOrAdmin={profile?.isAdmin || profile?.id === comment.user_id}
					>
						{activeCommentId === comment.id && (
							<InputComment
								onSubmit={(value) => {
									addNewAnswer(value, comment.id);
								}}
								isLoading={isLoading}
							/>
						)}

						{comment.answers.map((answer) => (
							<Comment
								key={answer.id}
								id={answer.id}
								content={answer.content}
								likes={answer.likes}
								user_name={answer.user_name}
								user_image={answer.user_image || ""}
								created_at={answer.created_at}
								logged_user_liked={answer.logged_user_liked}
								handleAnswer={() =>
									setActiveCommentId(
										activeCommentId === answer.id ? null : answer.id,
									)
								}
								handleDelete={() => {
									setDeleteModal(true);
									setSelectedAnswer(answer.id);
								}}
								handleLike={() => handleLikeComment(answer.id, true)}
								isOwnerOrAdmin={
									profile?.isAdmin || profile?.id === answer.user_id
								}
							/>
						))}
					</Comment>
				</div>
			))}

			<hr className="h-px border-t-neutral-200" />

			{deleteModal && (
				<DeleteModal
					title="Deseja realmente excluir este comentário?"
					icon="chat-error.svg"
					confirmText="Excluir"
					isLoading={isLoading}
					cancelAction={() => setDeleteModal(false)}
					confirmAction={handleDeleteComment}
					closeModal={() => setDeleteModal(false)}
				/>
			)}
		</div>
	);
}
