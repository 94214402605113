import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import {
	fetchAdminProductsNiches,
	type Niche,
} from "@/api/admin/products/niches";
import {
	fetchAdminProductsPlatforms,
	type Platform,
} from "@/api/admin/products/platforms";
import { fetchAdminProductsTypes, type Type } from "@/api/admin/products/type";
import { fetchProducts, Product } from "@/api/app/products/list";
import CloseIcon from "@/assets/close.svg?react";
import InfoIcon from "@/assets/info.svg?react";
import OptionsIcon from "@/assets/options.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import { ProductCard } from "@/components/product-card";
import { SkeletonProductCard } from "@/components/skeletons/product-card";
import { Button } from "@/components/ui/button";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useNavigationStore } from "@/store/navigation-store";

export function Products() {
	useEffect(() => {
		const paths = [
			{
				name: "Produtos",
				path: "/products",
			},
		];

		setPaths(paths);
	}, []);

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	const [isLoadingRecent, setIsLoadingRecent] = useState(true);
	const [isLoadingBestSelling, setIsLoadingBestSelling] = useState(true);
	const [isLoadingHighestCommissions, setIsLoadingHighestCommissions] =
		useState(true);

	const [recentProducts, setRecentProducts] = useState<Product[]>([]);
	const [bestSellingProducts, setBestSellingProducts] = useState<Product[]>([]);
	const [highestCommissionsProducts, setHighestCommissionsProducts] = useState<
		Product[]
	>([]);
	const [types, setTypes] = useState<Type[]>([]);
	const [platforms, setPlatforms] = useState<Platform[]>([]);
	const [niches, setNiches] = useState<Niche[]>([]);

	const [filterIsOpen, setFilterIsOpen] = useState(false);

	const filter = z.object({
		name: z.string().optional(),
		type: z.string().optional(),
		niche: z.string().optional(),
		platform: z.enum(["Braip", "Monetizze", "The Mart"]).optional(),
	});

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>();

	async function getProducts(
		sortBy: "created_at" | "commission_value" | "sales",
	) {
		if (sortBy === "created_at") setIsLoadingRecent(true);
		if (sortBy === "sales") setIsLoadingBestSelling(true);
		if (sortBy === "commission_value") setIsLoadingHighestCommissions(true);

		const response = await fetchProducts({ sortBy: sortBy });

		if (response.status === "success") {
			if (sortBy === "created_at") {
				setRecentProducts(response.data);
				setIsLoadingRecent(false);
				return;
			}

			if (sortBy === "sales") {
				setBestSellingProducts(response.data);
				setIsLoadingBestSelling(false);
				return;
			}

			if (sortBy === "commission_value") {
				setHighestCommissionsProducts(response.data);
				setIsLoadingHighestCommissions(false);
				return;
			}
		}
	}

	async function fetchTypes() {
		const response = await fetchAdminProductsTypes();

		if (response.status === "success") {
			setTypes(response.data);
		}
	}

	async function fetchPlatforms() {
		const response = await fetchAdminProductsPlatforms();

		if (response.status === "success") {
			setPlatforms(response.data);
		}
	}

	async function fetchNiches() {
		const response = await fetchAdminProductsNiches();

		if (response.status === "success") {
			setNiches(response.data);
		}
	}

	async function handleFilterSubmit(data: Filter) {
		setIsLoadingRecent(true);
		setIsLoadingBestSelling(true);
		setIsLoadingHighestCommissions(true);

		const response = await fetchProducts({
			sortBy: "created_at",
			name: data.name || undefined,
			type: data.type,
			platform: data.platform,
			niche: data.niche,
		});

		if (response.status === "success") {
			setRecentProducts(response.data);
			setIsLoadingRecent(false);
		}

		const response2 = await fetchProducts({
			sortBy: "sales",
			name: data.name || undefined,
			type: data.type,
			platform: data.platform,
			niche: data.niche,
		});

		if (response2.status === "success") {
			setBestSellingProducts(response2.data);
			setIsLoadingBestSelling(false);
		}

		const response3 = await fetchProducts({
			sortBy: "commission_value",
			name: data.name || undefined,
			type: data.type,
			platform: data.platform,
			niche: data.niche,
		});

		if (response3.status === "success") {
			setHighestCommissionsProducts(response3.data);
			setIsLoadingHighestCommissions(false);
		}

		setFilterIsOpen(false);
	}

	const navigate = useNavigate();

	useEffect(() => {
		getProducts("created_at");
		getProducts("sales");
		getProducts("commission_value");
		fetchTypes();
		fetchPlatforms();
		fetchNiches();
	}, []);

	return (
		<>
			<Helmet title="Produtos" />

			<div className="flex flex-col gap-6">
				<header className="flex items-center justify-between">
					<h2 className="font-inter text-P2 font-medium leading-140 text-neutral-1100">
						Todos os produtos
					</h2>

					<div className="flex items-center gap-6">
						<button
							className="hidden h-10 items-center justify-center gap-2 rounded-[6px] bg-transparent px-6 text-neutral-600 transition-all duration-300 hover:bg-neutral-200 hover:text-neutral-1100 md:flex"
							onClick={() => {
								navigate("/help");
							}}
						>
							<InfoIcon width={18} height={18} />

							<p className="font-inter text-P6 font-semibold leading-160">
								Saiba mais
							</p>
						</button>

						<button
							className="flex h-10 items-center justify-center gap-2 rounded-[6px] bg-neutral-200 px-6 text-neutral-1100 transition-all duration-300 hover:bg-opacity-40 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-400"
							disabled={
								isLoadingRecent &&
								isLoadingBestSelling &&
								isLoadingHighestCommissions
							}
							onClick={() => setFilterIsOpen(!filterIsOpen)}
						>
							<OptionsIcon />

							<p className="font-inter text-P6 font-semibold leading-160">
								Filtro
							</p>
						</button>
					</div>
				</header>

				<div className="flex h-full flex-col gap-10">
					<div
						className={`flex max-h-0 flex-col gap-8 rounded-[10px] bg-neutral-100 bg-opacity-60 opacity-0 transition-all duration-500 ${filterIsOpen && "max-h-fit p-6 opacity-100"}`}
					>
						{filterIsOpen && (
							<Form {...form}>
								<div className="flex w-full flex-col items-center gap-6 md:flex-row">
									<div
										className={`relative w-full ${isLoadingRecent || isLoadingBestSelling || isLoadingHighestCommissions ? "text-neutral-600" : "text-neutral-200"} focus-within:text-neutral-1100`}
									>
										<Input
											placeholder="Pesquise por um produto"
											className={`h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:!text-P5 ${isLoadingRecent || isLoadingBestSelling || isLoadingHighestCommissions ? "placeholder:text-neutral-600" : "placeholder:text-neutral-200"}`}
											{...form.register("name")}
										/>
										<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

										{form.watch("name") !== "" && (
											<button
												className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-200 text-neutral-1100"
												onClick={() => form.setValue("name", "")}
											>
												<CloseIcon />
											</button>
										)}
									</div>

									<FormField
										control={form.control}
										name="niche"
										render={({ field }) => (
											<FormItem className="w-1/2">
												<Select
													onValueChange={(e) => field.onChange(e)}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Nicho" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														{niches.map((type) => (
															<SelectItem value={type.id} key={type.id}>
																{type.name}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="type"
										render={({ field }) => (
											<FormItem className="w-1/2">
												<Select
													onValueChange={(e) => field.onChange(e)}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Tipo" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														{types.map((type) => (
															<SelectItem value={type.id} key={type.id}>
																{type.name}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="platform"
										render={({ field }) => (
											<FormItem className="w-1/2">
												<Select
													onValueChange={(e) => field.onChange(e)}
													defaultValue={field.value}
													value={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Plataforma" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														{platforms.map((platform) => (
															<SelectItem value={platform.id} key={platform.id}>
																{platform.name}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>
								</div>

								<div className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
									<div>
										<span className="font-inter text-P5 font-normal leading-160 text-neutral-400">
											Nenhum filtro aplicado
										</span>
									</div>

									<div className="flex gap-4">
										<Button
											className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] bg-transparent font-inter !text-P6 font-semibold leading-160 text-neutral-600 transition-all duration-500 hover:bg-neutral-200 disabled:bg-transparent disabled:text-neutral-400"
											variant="secondary"
											onClick={() => {
												form.setValue("name", undefined);
												form.setValue("platform", undefined);
												form.setValue("type", undefined);
												form.setValue("niche", undefined);
												setFilterIsOpen(false);
												handleFilterSubmit({});
											}}
										>
											Limpar filtro
										</Button>
										<Button
											className="flex !h-10 !w-[140px] flex-1 items-center justify-center gap-2 rounded-[8px] font-inter !text-P6 font-semibold leading-160"
											onClick={form.handleSubmit(async (data) => {
												handleFilterSubmit(data);
											})}
										>
											Aplicar
										</Button>
									</div>
								</div>
							</Form>
						)}
					</div>

					{isLoadingRecent ? (
						<div className="mt-10">
							<Carousel>
								<CarouselContent className="-ml-8">
									{Array.from({ length: 6 }).map((_, index) => (
										<CarouselItem className="basis-auto pl-8" key={index}>
											<SkeletonProductCard />
										</CarouselItem>
									))}
								</CarouselContent>
								<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
								<CarouselNext className="right-0 top-[-54px]" />
								<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Lançados recentemente
								</p>
							</Carousel>
						</div>
					) : (
						recentProducts.length > 0 && (
							<div className="mt-10">
								<Carousel>
									<CarouselContent className="-ml-8">
										{recentProducts.map((product) => (
											<CarouselItem
												className="basis-auto pl-8"
												key={product.id}
											>
												<ProductCard
													id={product.id}
													name={product.name}
													image={product.image}
													type={product.type.name}
												/>
											</CarouselItem>
										))}
									</CarouselContent>
									<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
									<CarouselNext className="right-0 top-[-54px]" />
									<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
										Lançados recentemente
									</p>
								</Carousel>
							</div>
						)
					)}

					{isLoadingBestSelling ? (
						<div className="mt-10">
							<Carousel>
								<CarouselContent className="-ml-8">
									{Array.from({ length: 6 }).map((_, index) => (
										<CarouselItem className="basis-auto pl-8" key={index}>
											<SkeletonProductCard />
										</CarouselItem>
									))}
								</CarouselContent>
								<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
								<CarouselNext className="right-0 top-[-54px]" />
								<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Mais vendidos
								</p>
							</Carousel>
						</div>
					) : (
						bestSellingProducts.length > 0 && (
							<div className="mt-10">
								<Carousel>
									<CarouselContent className="-ml-8">
										{bestSellingProducts.map((product) => (
											<CarouselItem
												className="basis-auto pl-8"
												key={product.id}
											>
												<ProductCard
													id={product.id}
													name={product.name}
													image={product.image}
													type={product.type.name}
												/>
											</CarouselItem>
										))}
									</CarouselContent>
									<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
									<CarouselNext className="right-0 top-[-54px]" />
									<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
										Mais vendidos
									</p>
								</Carousel>
							</div>
						)
					)}

					{isLoadingHighestCommissions ? (
						<div className="mt-10">
							<Carousel>
								<CarouselContent className="-ml-8">
									{Array.from({ length: 6 }).map((_, index) => (
										<CarouselItem className="basis-auto pl-8" key={index}>
											<SkeletonProductCard />
										</CarouselItem>
									))}
								</CarouselContent>
								<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
								<CarouselNext className="right-0 top-[-54px]" />
								<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Maiores comissões
								</p>
							</Carousel>
						</div>
					) : (
						highestCommissionsProducts.length > 0 && (
							<div className="mt-10">
								<Carousel>
									<CarouselContent className="-ml-8">
										{highestCommissionsProducts.map((product) => (
											<CarouselItem
												className="basis-auto pl-8"
												key={product.id}
											>
												<ProductCard
													id={product.id}
													name={product.name}
													image={product.image}
													type={product.type.name}
												/>
											</CarouselItem>
										))}
									</CarouselContent>
									<CarouselPrevious className="right-14 top-[-54px] disabled:bg-neutral-100" />
									<CarouselNext className="right-0 top-[-54px]" />
									<p className="absolute left-0 top-[-48px] font-inter text-P3 font-medium leading-160 text-neutral-1100">
										Maiores comissões
									</p>
								</Carousel>
							</div>
						)
					)}

					{!isLoadingRecent &&
						!isLoadingBestSelling &&
						!isLoadingHighestCommissions &&
						recentProducts.length === 0 &&
						bestSellingProducts.length === 0 &&
						highestCommissionsProducts.length === 0 && (
							<div className="flex flex-grow items-center justify-center py-36">
								<div className="flex flex-col items-center justify-center gap-6">
									<span className="flex items-center justify-center rounded-[14px] border border-neutral-200 bg-primary-600 bg-opacity-[0.04] p-5 text-primary-600">
										<Search2Icon />
									</span>
									<h3 className="text-center font-inter font-medium leading-160 text-neutral-1100">
										Não encontramos nenhum produto com os filtros aplicados
									</h3>
								</div>
							</div>
						)}
				</div>
			</div>
		</>
	);
}
