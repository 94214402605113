import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import {
	fetchAdminProductsNiches,
	type Niche,
} from "@/api/admin/products/niches";
import { fetchAdminProductsPlatforms } from "@/api/admin/products/platforms";
import { fetchAdminProductsTypes, type Type } from "@/api/admin/products/type";
import { updateAdminProducts } from "@/api/admin/products/update";
import LinkIcon from "@/assets/link.svg?react";
import { AlertModal } from "@/components/alert-modal";
import { FileCard } from "@/components/file-card";
import { FileUploader } from "@/components/file-uploader";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { FloatingLabelInput } from "@/components/ui/float-input";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { useAdminProductStore } from "@/store/admin-product";
import { useNavigationStore } from "@/store/navigation-store";

import { SkeletonAdminProductsShowDetails } from "./skeletons/details";

interface PlatformForm {
	external_id: string;
	id: string;
	link: string;
	platform: string;
	platform_id: string;
	url_postback: string;
	isActive: boolean;
}

export function AdminProductsShowDetails() {
	const filter = z
		.object({
			name: z.string().min(1, {
				message: "Você precisa adicionar um nome para o produto",
			}),
			type: z.string(),
			niche: z.string(),
			telegram: z.string().url({
				message:
					"Você precisa adicionar um link do Telegram para o seu produto",
			}),
			status_sale_link: z.boolean().default(false),
			sale_link: z.string().optional(),
			image: z.union([z.instanceof(File), z.string()]),
			platforms: z
				.array(
					z.object({
						external_id: z.string(),
						id: z.string(),
						link: z.string(),
						platform: z.string(),
						platform_id: z.string(),
						url_postback: z.string(),
						isActive: z.boolean(),
					}),
				)
				.refine(
					(platforms) =>
						platforms.some(
							(platform) =>
								platform.link.trim() !== "" &&
								platform.external_id.trim() !== "",
						),
					{
						message:
							"Pelo menos uma plataforma deve estar completamente preenchida (link e código do produto).",
						path: ["platforms"],
					},
				),
		})
		.refine(
			(data) =>
				!data.status_sale_link ||
				(data.sale_link && /^https?:\/\//.test(data.sale_link)),
			{
				message:
					"Você precisa fornecer um link de compra válido para o produto.",
				path: ["sale_link"],
			},
		);

	type Filter = z.infer<typeof filter>;

	const form = useForm<Filter>({
		resolver: zodResolver(filter),
	});

	const { product } = useAdminProductStore();

	const [successModal, setSuccessModal] = useState(false);
	const [isFormInitialized, setIsFormInitialized] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [types, setTypes] = useState<Type[]>([]);
	const [niches, setNiches] = useState<Niche[]>([]);
	const [platforms, setPlatforms] = useState<PlatformForm[]>([]);

	async function fetchTypes() {
		setIsLoading(true);
		const response = await fetchAdminProductsTypes();

		if (response.status === "success") {
			setTypes(response.data);
		}

		setIsLoading(false);
	}

	async function fetchNiches() {
		setIsLoading(true);

		const response = await fetchAdminProductsNiches();

		if (response.status === "success") {
			setNiches(response.data);
		}

		setIsLoading(false);
	}

	async function fetchPlatforms() {
		setIsLoading(true);

		const response = await fetchAdminProductsPlatforms();

		if (response.status === "success") {
			const platforms = response.data.map((platform) => ({
				id: "",
				platform_id: platform.id,
				link: "",
				external_id: "",
				platform: platform.name,
				url_postback: "",
				isActive: false,
			}));

			setPlatforms(platforms);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		fetchTypes();
		fetchNiches();
		fetchPlatforms();
	}, []);

	useEffect(() => {
		if (!product || isFormInitialized || !platforms.length) return;

		form.setValue("name", product.name);
		form.setValue("type", product.type.id);
		form.setValue("niche", product.niche.id);
		form.setValue("telegram", product.telegram || "");
		form.setValue("image", product.image);

		form.setValue("status_sale_link", product.status_sale_link ? true : false);
		form.setValue("sale_link", product.sale_link || "");

		if (platforms.length > 0) {
			const productPlatforms = platforms.map((platform) => {
				const productPlatform = product.platforms.find(
					(productPlatform) =>
						productPlatform.platform_id === platform.platform_id,
				);

				if (productPlatform) {
					return {
						...platform,
						...productPlatform,
						isActive: true,
					};
				}

				return {
					...platform,
					isActive: false,
				};
			});

			form.setValue("platforms", productPlatforms);
			setPlatforms(productPlatforms);
		}

		setIsFormInitialized(true);
	}, [product, platforms, form, isFormInitialized]);

	const { id } = useParams<{ id: string }>();

	const [setPaths] = useNavigationStore((state) => [state.setPaths]);

	useEffect(() => {
		const paths = [
			{
				name: "Configurações",
				path: "/admin",
			},
			{
				name: "Produtos",
				path: "/admin/products",
			},
			{
				name: product?.name || "Carregando...",
				path: `/admin/products/${id}`,
			},
		];

		setPaths(paths);
	}, [product]);

	async function handleCreateProduct(data: Filter) {
		try {
			if (!id) {
				console.error("ID do produto não encontrado.");
				return;
			}

			const formData = new FormData();

			formData.append("name", data.name);
			formData.append("type_id", data.type);
			formData.append("niche_id", data.niche);
			formData.append("telegram_link", data.telegram);
			formData.append("status_sale_link", data.status_sale_link ? "1" : "0");
			if (data.sale_link) {
				formData.append("sale_link", data.sale_link);
			}

			if (data.image instanceof File) {
				formData.append("image", data.image);
			}

			data.platforms.forEach((platform, index) => {
				if (!platform.link.trim() || !platform.external_id.trim()) {
					return;
				}

				formData.append(
					`platforms[${index}][platform_id]`,
					platform.platform_id,
				);
				formData.append(`platforms[${index}][link]`, platform.link);
				formData.append(
					`platforms[${index}][external_id]`,
					platform.external_id,
				);
			});

			const response = await updateAdminProducts(formData, id);

			if (response.status === "success") {
				setSuccessModal(true);
			}

			if (response.status === "error") {
				const { errors } = response;

				for (const key in errors) {
					form.setError(key as keyof Filter, {
						type: "manual",
						message: errors[key],
					});
				}

				setIsLoading(false);
			}
		} catch (error) {
			console.error("Erro ao enviar formulário:", error);
		}
	}

	const [showTooltip, setShowTooltip] = useState(false);

	const handleCopy = (
		text: string,
		setShowTooltip: (value: boolean) => void,
	) => {
		navigator.clipboard.writeText(text);
		setShowTooltip(true);
		setTimeout(() => setShowTooltip(false), 2000);
	};

	const navigate = useNavigate();

	if (isLoading || !platforms.length) {
		return <SkeletonAdminProductsShowDetails />;
	}

	if (!isLoading) {
		return (
			<Form {...form}>
				<form
					onSubmit={form.handleSubmit(async (values) => {
						handleCreateProduct({
							...values,
						});
					})}
				>
					<div className="mb-28 flex flex-col gap-8">
						<div className="flex w-full flex-col items-center gap-6">
							<div className="flex w-full flex-col gap-2">
								<FloatingLabelInput
									id="name"
									type="text"
									label="Nome do produto"
									bgColor="bg-neutral-0"
									hasError={!!form.formState.errors.name}
									{...form.register("name")}
								/>

								{form.formState.errors.name && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.name.message}
									</p>
								)}
							</div>

							<div className="flex w-full gap-6">
								<FormField
									control={form.control}
									name="type"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-full">
														<SelectValue placeholder="Tipo de produto" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{types.map((type) => (
														<SelectItem value={type.id} key={type.id}>
															{type.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="niche"
									render={({ field }) => (
										<FormItem className="w-full">
											<Select
												onValueChange={(e) => {
													field.onChange(e);
												}}
												defaultValue={field.value}
												value={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-full">
														<SelectValue placeholder="Nicho" />
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{niches.map((niche) => (
														<SelectItem value={niche.id} key={niche.id}>
															{niche.name}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<div className="flex flex-col gap-2">
								<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Imagem do produto
								</p>

								<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
									Essa será a imagem de divulgação do seu produto.
								</span>
							</div>

							<div className="flex w-full flex-col gap-2">
								{typeof form.watch("image") === "string" &&
								form.watch("image") !== "" ? (
									<FileCard
										url={form.watch("image") as string}
										onRemove={() => form.setValue("image", "")}
									/>
								) : form.watch("image") instanceof File ? (
									<FileCard
										file={form.watch("image") as File}
										onRemove={() => form.setValue("image", "")}
									/>
								) : (
									<FileUploader
										maxFiles={1}
										maxSize={10 * 1024 * 1024}
										accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
										onUpload={async (e) => {
											form.setValue("image", e[0]);
										}}
										disabled={false}
									/>
								)}

								{form.formState.errors.image && (
									<p className="font-inter text-P6 font-normal leading-160 text-red-600">
										{form.formState.errors.image.message}
									</p>
								)}
							</div>
						</div>
						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Links dos botões
							</p>

							<div className="flex flex-col gap-4">
								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex items-center justify-between gap-2">
										<div className="flex flex-col gap-2">
											<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
												Botão de compra
											</p>
											<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
												Adicione o link de compra do produto para seus afiliados
											</span>
										</div>
										<Switch
											checked={form.watch("status_sale_link")}
											onCheckedChange={(checked) =>
												form.setValue("status_sale_link", checked)
											}
										/>
									</div>

									{form.watch("status_sale_link") && (
										<div className="flex w-full flex-col gap-2">
											<FloatingLabelInput
												id="sale_link"
												type="text"
												label="Link do botão de compra"
												bgColor="bg-transparent"
												hasError={!!form.formState.errors.sale_link}
												{...form.register("sale_link")}
											/>

											{form.formState.errors.sale_link && (
												<p className="font-inter text-P6 font-normal leading-160 text-red-600">
													{form.formState.errors.sale_link.message}
												</p>
											)}
										</div>
									)}
								</div>

								<div className="flex flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
									<div className="flex flex-col gap-2">
										<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
											Botão do Telegram
										</p>

										<span className="font-inter text-P5 font-normal leading-160 text-neutral-500">
											Adicione o link do Telegram para o seu produto.
										</span>
									</div>

									<div className="flex w-full flex-col gap-2">
										<FloatingLabelInput
											id="name"
											type="text"
											label="Link do Telegram"
											bgColor="bg-transparent"
											hasError={!!form.formState.errors.telegram}
											{...form.register("telegram")}
										/>

										{form.formState.errors.telegram && (
											<p className="font-inter text-P6 font-normal leading-160 text-red-600">
												{form.formState.errors.telegram.message}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="flex flex-col gap-8 rounded-[8px] border border-solid border-neutral-200 p-6">
							<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
								Links de afiliados
							</p>

							{form.watch("platforms") &&
								form.watch("platforms").length > 0 && (
									<div className="w-full">
										{form.watch("platforms").map((platform, index) => (
											<Accordion
												type="single"
												collapsible
												defaultValue={
													platform.isActive
														? `item-${platform.id}-${index}`
														: undefined
												}
												key={index}
											>
												<AccordionItem
													value={`item-${platform.id}-${index}`}
													className="!bg-neutral-1100 !bg-opacity-[0.03]"
												>
													<AccordionTrigger>
														{platform.platform}
													</AccordionTrigger>
													<AccordionContent>
														<div className="flex flex-col gap-6">
															<div className="flex gap-6">
																<div className="w-full">
																	<FloatingLabelInput
																		id="name"
																		type="text"
																		label="Link de afiliado"
																		bgColor="bg-transparent"
																		hasError={
																			!!form.formState.errors.platforms?.[index]
																				?.link
																		}
																		{...form.register(
																			`platforms.${index}.link`,
																		)}
																	/>
																</div>

																<div className="w-52">
																	<FloatingLabelInput
																		id="name"
																		type="text"
																		label="Código do produto"
																		bgColor="bg-transparent"
																		hasError={
																			!!form.formState.errors.platforms?.[index]
																				?.external_id
																		}
																		{...form.register(
																			`platforms.${index}.external_id`,
																		)}
																	/>
																</div>
															</div>

															{platform.url_postback && (
																<div className="flex h-[52px] items-center justify-between rounded-[8px] bg-neutral-200 py-2 pl-4 pr-2">
																	<p className="font-inter text-P5 font-normal leading-160 text-neutral-500">
																		{platform.url_postback}
																	</p>

																	<span
																		className="relative cursor-pointer"
																		onClick={() =>
																			handleCopy(
																				platform.url_postback,
																				setShowTooltip,
																			)
																		}
																	>
																		<button
																			type="button"
																			className="flex h-8 items-center justify-center gap-2 rounded-[4px] bg-primary-600 px-3 transition-all duration-500 hover:bg-primary-800"
																		>
																			<LinkIcon />

																			<p className="font-inter text-P7 font-semibold leading-160 text-neutral-1100">
																				Copiar link
																			</p>
																		</button>

																		{showTooltip && (
																			<div className="relative">
																				<span className="absolute -top-16 left-1/2 flex w-[120px] -translate-x-1/2 flex-col items-center rounded bg-white p-1 px-3 py-2 text-center text-xs text-black shadow-lg">
																					Copiado!
																				</span>
																				<span className="absolute bottom-[20px] left-1/2 -translate-x-1/2 border-[6px] border-x-transparent border-b-transparent border-t-white"></span>
																			</div>
																		)}
																	</span>
																</div>
															)}
														</div>
													</AccordionContent>
												</AccordionItem>
											</Accordion>
										))}
									</div>
								)}
						</div>
					</div>

					<footer className="fixed bottom-0 left-0 right-0 z-[6] flex items-center justify-end bg-neutral-0 px-8 pb-8 pt-4 xl:left-[257px]">
						<div className="flex w-full items-center justify-center gap-4 md:w-auto">
							<Button
								className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] !bg-neutral-200 font-inter !text-P5 font-semibold leading-160 text-neutral-1100 transition-all duration-500 hover:!bg-opacity-40 md:!w-[140px]
					"
								variant="secondary"
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancelar
							</Button>
							<Button
								className="flex !h-12 w-full items-center justify-center gap-2 rounded-[8px] font-inter !text-P5 font-semibold leading-160 md:!w-[140px]"
								onClick={() => {
									form.handleSubmit(async (values) => {
										handleCreateProduct({
											...values,
										});
									});
								}}
							>
								Salvar
							</Button>
						</div>
					</footer>
				</form>

				{successModal && (
					<AlertModal
						title="Produto atualizado com sucesso!"
						icon="done.svg"
						confirmText="Concluir"
						hasCancel={false}
						confirmAction={() => {
							setSuccessModal(false);
						}}
						closeModal={() => {
							setSuccessModal(false);
						}}
					/>
				)}
			</Form>
		);
	}
}
