type Answers = {
	title: string;
	amount: number;
};

interface OnboardingCardProps {
	title: string;
	answers: Answers[];
}

export function OnboardingCard({ title, answers }: OnboardingCardProps) {
	const totalAnswers = answers.reduce((sum, answer) => sum + answer.amount, 0);

	return (
		<div className="flex w-[360px] flex-col gap-8 rounded-[8px] bg-neutral-1100 bg-opacity-[0.03] p-6">
			<p
				className="line-clamp-2 text-ellipsis font-inter text-P4 font-medium leading-160 text-neutral-1100"
				title={title}
			>
				{title}
			</p>

			<div className="flex flex-col gap-6">
				<div className="flex items-center justify-between border-b border-solid border-neutral-200 pb-4">
					<p className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Respostas
					</p>

					<span className="font-inter text-P6 font-normal leading-160 text-neutral-600">
						Qnt.
					</span>
				</div>

				<div className="flex flex-col gap-4">
					{answers.map((answer, index) => {
						const percentage = totalAnswers
							? (answer.amount / totalAnswers) * 100
							: 0;

						return (
							<div key={index} className="flex flex-col gap-2">
								<div className="flex items-center justify-between gap-6">
									<p
										className="line-clamp-1 text-ellipsis font-inter text-P5 font-normal leading-160 text-neutral-800"
										title={answer.title}
									>
										{answer.title}
									</p>

									{answer.amount && (
										<p className="font-inter text-P5 font-medium leading-160 text-neutral-1100">
											{answer.amount}
										</p>
									)}
								</div>

								<div className="mt-3 h-1 w-full rounded-[100px] bg-neutral-200">
									<div
										className="h-full rounded-[100px] bg-primary-600 transition-all"
										style={{ width: `${percentage}%` }}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
