import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { getPanelUsers, type Users } from "@/api/admin/panel/users";
import { OnboardingCard } from "@/components/onboarding-card";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";

import { AdminPanelUsersMetrics } from "./metrics";
import { AdminPanelUsersPeriod } from "./period";

export function AdminPanelUsers() {
	const [periodState, setPeriodState] = useState<string>("last_7_days");
	const [isLoading, setIsLoading] = useState(false);
	const [users, setUsers] = useState<Users | null>(null);

	async function fetchPanelUsers() {
		setIsLoading(true);

		const period = {
			today: [dayjs().format("DD/MM/YYYY"), dayjs().format("DD/MM/YYYY")],
			last_7_days: [
				dayjs().subtract(7, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_30_days: [
				dayjs().subtract(30, "day").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			this_month: [
				dayjs().startOf("month").format("DD/MM/YYYY"),
				dayjs().format("DD/MM/YYYY"),
			],
			last_month: [
				dayjs().subtract(1, "month").startOf("month").format("DD/MM/YYYY"),
				dayjs().subtract(1, "month").endOf("month").format("DD/MM/YYYY"),
			],
			no_filter: [],
		};

		const response = await getPanelUsers({
			period: period[periodState as keyof typeof period],
		});

		if (response.status === "success") {
			setUsers(response.data);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		fetchPanelUsers();
	}, [periodState]);

	const containerRef = useRef<HTMLDivElement>(null);
	const [availableWidth, setAvailableWidth] = useState(0);

	useEffect(() => {
		function updateWidth() {
			if (containerRef.current) {
				setAvailableWidth(containerRef.current.clientWidth);
			}
		}

		window.addEventListener("resize", updateWidth);
		updateWidth(); // Chama na montagem

		return () => window.removeEventListener("resize", updateWidth);
	}, []);

	return (
		<div ref={containerRef}>
			{isLoading ? (
				<div className="flex flex-col gap-8">
					<Skeleton className="h-10 w-full" />

					<div className="flex gap-8">
						<Skeleton className="h-36 w-full" />
						<Skeleton className="h-36 w-full" />
					</div>

					<Skeleton className="h-96 w-full" />
				</div>
			) : (
				users && (
					<div className="flex flex-col gap-12">
						<Select
							onValueChange={(value) => setPeriodState(value)}
							defaultValue={periodState}
							value={periodState}
						>
							<SelectTrigger className="w-full">
								<SelectValue placeholder="Selecione um período" />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="today">Hoje</SelectItem>
								<SelectItem value="last_7_days">Últimos 7 dias</SelectItem>
								<SelectItem value="last_30_days">Últimos 30 dias</SelectItem>
								<SelectItem value="this_month">Este mês</SelectItem>
								<SelectItem value="last_month">Mês passado</SelectItem>
								<SelectItem value="no_filter">Sem filtro</SelectItem>
							</SelectContent>
						</Select>

						<div className="flex flex-col gap-8">
							<AdminPanelUsersMetrics
								new_users={users?.new_users}
								new_integration_users={users?.new_integration_users}
							/>

							<AdminPanelUsersPeriod
								data_new_users_and_integrations={
									users?.data_new_users_and_integrations
								}
							/>

							<div
								className="flex flex-col gap-10 rounded-[8px] border border-solid border-neutral-100 p-6"
								style={{ width: availableWidth - 6 }}
							>
								<h3 className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
									Perguntas onboarding
								</h3>

								<ScrollArea>
									<div className="mb-8 flex w-full gap-6">
										{users?.onboarding_questions &&
											Object.entries(users.onboarding_questions).map(
												([questionTitle, questionData], index) => (
													<OnboardingCard
														key={index}
														title={questionTitle}
														answers={Object.entries(
															questionData.onboarding_answers,
														).map(([answerTitle, answerData]) => ({
															title: answerTitle,
															// @ts-expect-error
															amount: answerData.count ?? 0,
														}))}
													/>
												),
											)}
									</div>

									<ScrollBar orientation="horizontal" />
								</ScrollArea>
							</div>
						</div>
					</div>
				)
			)}
		</div>
	);
}
