import { useEffect, useState } from "react";

import CloseIcon from "@/assets/close.svg?react";
import Close2Icon from "@/assets/close-2.svg?react";
import Search2Icon from "@/assets/search-2.svg?react";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/accordion-lessons";
import { LessonCard } from "@/components/lesson-card";
import { Input } from "@/components/ui/input";
import { useTrainingStore } from "@/store/training";

interface LessonsMenuProps {
	closeMenu: () => void;
}

export function AcademyTrainingMenu({ closeMenu }: LessonsMenuProps) {
	const { training } = useTrainingStore();

	if (!training) return null;

	const { modules } = training;

	const [filteredModules, setFilteredModules] = useState(modules);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		if (!searchTerm) {
			setFilteredModules(modules);
		} else {
			const filtered = modules.filter((module) =>
				module.lessons.some((lesson) =>
					lesson.name.toLowerCase().includes(searchTerm.toLowerCase()),
				),
			);
			setFilteredModules(filtered);
		}
	}, [searchTerm, modules]);

	return (
		<nav className="flex w-full max-w-[90%] flex-col rounded-r-[12px] bg-neutral-100 md:max-w-[352px]">
			<div className="flex items-center justify-between px-6 pb-4 pt-8">
				<p className="font-inter text-P3 font-medium leading-160 text-neutral-1100">
					Todas as aulas
				</p>

				<button onClick={closeMenu}>
					<Close2Icon width={24} height={24} />
				</button>
			</div>

			<div className="flex flex-col px-6 pb-2 pt-4">
				<div className="relative text-neutral-600 focus-within:text-neutral-1100">
					<Input
						placeholder="Pesquise por uma aula"
						className="h-14 bg-transparent indent-8 font-inter font-normal leading-160 placeholder:text-P5 placeholder:text-neutral-600"
						onChange={(e) => setSearchTerm(e.target.value)}
						value={searchTerm}
					/>
					<Search2Icon className="absolute left-4 top-1/2 -translate-y-1/2" />

					{searchTerm && (
						<button
							className="absolute right-4 top-1/2 flex size-5 -translate-y-1/2 items-center justify-center rounded-full bg-neutral-600 text-neutral-1100"
							onClick={() => setSearchTerm("")}
						>
							<CloseIcon />
						</button>
					)}
				</div>
			</div>

			<div className="flex flex-col overflow-y-auto p-6">
				<Accordion type="single" defaultValue="item-0" collapsible>
					{filteredModules.map((module, moduleIndex) => (
						<AccordionItem
							key={module.id || moduleIndex}
							value={`item-${moduleIndex}`}
						>
							<AccordionTrigger>
								<span className="text-left font-inter text-P6 font-medium leading-160 text-neutral-500">
									Módulo {moduleIndex + 1}
								</span>

								<p
									className="line-clamp-1 text-ellipsis font-inter text-P4 font-medium leading-160 text-neutral-1100"
									title={module.name}
								>
									{module.name}
								</p>
							</AccordionTrigger>
							<AccordionContent>
								{module.lessons.map((lesson, lessonIndex) => (
									<LessonCard
										key={lesson.id || lessonIndex}
										lessonId={lesson.id}
										trainingId={training.id}
										duration={lesson.duration}
										lesson={lessonIndex + 1}
										title={lesson.name}
										image={lesson.thumbnail}
										timeWatched={lesson.time_watched}
										size="small"
										isCompleted={lesson.watched}
									/>
								))}
							</AccordionContent>
							{moduleIndex < modules.length - 1 && (
								<hr className="h-px border-t-neutral-200 opacity-60" />
							)}
						</AccordionItem>
					))}
				</Accordion>
			</div>
		</nav>
	);
}
