import { Bold, Italic, Link, Underline } from "lucide-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { cn } from "@/lib/utils";

interface TextEditorProps {
	initialContent?: string;
	onChange?: (content: string) => void;
	className?: string;
	placeholder?: string;
}

export function TextEditor({
	initialContent = "",
	onChange,
	className = "",
	placeholder = "Start typing...",
}: TextEditorProps) {
	const editorRef = useRef<HTMLDivElement>(null);
	const [isLinkActive, setIsLinkActive] = useState(false);
	const [isBoldActive, setIsBoldActive] = useState(false);
	const [isItalicActive, setIsItalicActive] = useState(false);
	const [isUnderlineActive, setIsUnderlineActive] = useState(false);

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.innerHTML = initialContent || placeholder;
			modifyExistingLinks();
		}

		document.addEventListener("selectionchange", checkFormattingStatus);
		return () =>
			document.removeEventListener("selectionchange", checkFormattingStatus);
	}, [initialContent, placeholder]);

	const formatText = (command: string) => {
		document.execCommand(command, false);
		updateContent();
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			document.execCommand("insertLineBreak", false);
		}
	};

	const updateContent = () => {
		if (editorRef.current) {
			const newContent = editorRef.current.innerHTML;
			if (newContent !== placeholder) {
				modifyExistingLinks();
				onChange?.(newContent);
			}
		}
	};

	const modifyExistingLinks = () => {
		if (editorRef.current) {
			const links = editorRef.current.getElementsByTagName("a");
			for (let i = 0; i < links.length; i++) {
				links[i].target = "_blank";
				links[i].rel = "noopener noreferrer";
				links[i].className = "text-primary-600";
			}
		}
	};

	const handleFocus = () => {
		if (editorRef.current && editorRef.current.innerHTML === placeholder) {
			editorRef.current.innerHTML = "";
		}
	};

	const handleBlur = () => {
		if (editorRef.current && editorRef.current.innerHTML.trim() === "") {
			editorRef.current.innerHTML = placeholder;
		}
		updateContent();
	};

	// **Verifica se o cursor está dentro de um link, bold, itálico ou underline**
	const checkFormattingStatus = () => {
		const selection = window.getSelection();
		if (!selection || selection.rangeCount === 0) return;

		const range = selection.getRangeAt(0);
		const parentElement = range.commonAncestorContainer.parentElement;

		setIsLinkActive(parentElement?.tagName === "A");
		setIsBoldActive(document.queryCommandState("bold"));
		setIsItalicActive(document.queryCommandState("italic"));
		setIsUnderlineActive(document.queryCommandState("underline"));
	};

	// **Adiciona ou Remove Link**
	const toggleLink = () => {
		const selection = window.getSelection();
		if (!selection || selection.rangeCount === 0) return;

		const range = selection.getRangeAt(0);
		const parentElement = range.commonAncestorContainer.parentElement;

		if (parentElement?.tagName === "A") {
			document.execCommand("unlink", false);
			setIsLinkActive(false);
		} else {
			let url = prompt("Enter URL:");
			if (url) {
				if (!url.startsWith("https")) {
					url = `https://${url}`;
				}

				document.execCommand("createLink", false, url);
				modifyExistingLinks();
				setIsLinkActive(true);
			}
		}

		updateContent();
	};

	return (
		<div
			className={`w-full rounded-[8px] border border-solid border-neutral-200 p-4 text-neutral-900 ${className}`}
		>
			<div className="mb-4 flex items-center gap-2">
				<button
					className={cn(
						"rounded p-2 transition-all duration-300",
						isBoldActive
							? "bg-neutral-200 text-neutral-1100"
							: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-1100",
					)}
					onClick={() => formatText("bold")}
					type="button"
				>
					<Bold className="h-4 w-4" />
				</button>
				<button
					className={cn(
						"rounded p-2 transition-all duration-300",
						isItalicActive
							? "bg-neutral-200 text-neutral-1100"
							: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-1100",
					)}
					onClick={() => formatText("italic")}
					type="button"
				>
					<Italic className="h-4 w-4" />
				</button>
				<button
					className={cn(
						"rounded p-2 transition-all duration-300",
						isUnderlineActive
							? "bg-neutral-200 text-neutral-1100"
							: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-1100",
					)}
					onClick={() => formatText("underline")}
					type="button"
				>
					<Underline className="h-4 w-4" />
				</button>
				<div className="mx-2 h-6 w-px bg-neutral-200" />
				<button
					className={cn(
						"rounded p-2 transition-all duration-300",
						isLinkActive
							? "bg-neutral-200 text-neutral-1100"
							: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-1100",
					)}
					onClick={toggleLink}
					type="button"
				>
					<Link className="h-4 w-4" />
				</button>
			</div>

			<div
				ref={editorRef}
				contentEditable
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				onFocus={handleFocus}
				className="min-h-[230px] space-y-6 text-[15px] leading-relaxed focus:outline-none [&_a]:text-primary-600"
			/>
		</div>
	);
}
