import type React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import FileDoneIcon from "@/assets/file-done.svg?react";
import { formatDateNotification } from "@/utils/date-format";

interface NotificationCardProps {
	wasRead: string | null;
	title: string;
	createdAt: string;
	readNotification: () => void;
	cleanNotification: () => void;
	lessonId?: string;
	trainingId?: string;
	productId?: string;
	moduleId?: string;
	commentId?: string;
	type: "product" | "training" | "comment";
}

export function NotificationCard({
	cleanNotification,
	readNotification,
	title,
	createdAt,
	wasRead,
	lessonId,
	trainingId,
	moduleId,
	productId,
	commentId,
	type,
}: NotificationCardProps) {
	const [isHovered, setIsHovered] = useState(false);
	const navigate = useNavigate();

	function handleHover() {
		setIsHovered(!isHovered);
	}

	function handleClick(e: React.MouseEvent) {
		e.preventDefault();
		readNotification();
		let path = "/";
		if (type === "product") {
			path = `/products/${productId}`;
		} else if (type === "training") {
			path = `/academy/${trainingId}`;
		} else if (type === "comment") {
			path = `/academy/${trainingId}/${moduleId}/${lessonId}/${commentId}`;
		}

		navigate(path);
	}

	return (
		<button
			className="flex cursor-pointer gap-4 rounded-[10px] bg-neutral-200 bg-opacity-40 p-4 transition-all duration-300 hover:bg-opacity-100"
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
			onClick={handleClick}
		>
			<div
				className={`relative flex items-center justify-center rounded-[8px] bg-neutral-200 p-3 text-primary-600 transition-all duration-300 ${
					isHovered && "!bg-neutral-300"
				}`}
			>
				<FileDoneIcon />
				{!wasRead && (
					<span className="absolute -left-1 -top-1 size-2.5 rounded-full bg-red-600" />
				)}
			</div>
			<div className="flex flex-1 flex-col gap-2">
				<p
					className="line-clamp-1 text-ellipsis text-left font-inter text-P5 font-medium leading-160 text-neutral-1100"
					title={title}
				>
					{title}
				</p>
				<div className="flex items-center justify-between">
					<p className="font-inter text-P6 font-normal leading-160 text-neutral-500">
						{formatDateNotification(createdAt)}
					</p>
					<span
						className="font-inter text-P7 font-medium leading-160 text-neutral-600 transition-colors hover:text-neutral-1100 hover:underline hover:underline-offset-4"
						onClick={(e) => {
							e.stopPropagation();
							cleanNotification();
						}}
					>
						Limpar
					</span>
				</div>
			</div>
		</button>
	);
}
